import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography as MuiTypography,
  Grid as MuiGrid,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Subtitle from "../shared/subtitle";
import cx from "classnames";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: 80,
    overflow: "hidden",
    opacity: 0.5,
    cursor: "pointer",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)",

    "&:hover": {
      autoAlpha: 1,
      boxShadow:
        '0px 0px 42px -10px rgba(122,122,122,0.65) !important',
      opacity: '1 !important',
    },

    [theme.breakpoints.down('xs')]: {
      maxHeight: 85,
    },
  },
  openCard: {
    autoAlpha: 1,
    boxShadow:
        '0px 0px 42px -10px rgba(122,122,122,0.65) !important',
    opacity: '1 !important',
    maxHeight: 320,
  },
  openCardMobile: {
    opacity: '1 !important',
  },
  content: {
    height: "100%",
    display: "flex",
  },
  icon: {
    color: theme.colors.orange,
    width: theme.spacing(5),
    height: theme.spacing(5),
    flexShrink: 0,
  },
  title: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: 1.3,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  titleContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 25,
    },
  },
  subtitle: {
    marginBottom: 0,
    marginLeft: 56,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  }
}));

const BenefitCard = forwardRef(({ open, onClick, title, icon: Icon, description, isMobile }, ref) => {
  const classes = useStyles();

  return (
      <Card ref={ref} elevation={5}
            className={cx(classes.card,
              {
                [classes.openCard]: open,
                [classes.openCardMobile]: isMobile,
              })}
            onClick={onClick}
      >
      <CardContent className={classes.content}>
        <div className={classes.descriptionWrapper}>
          <Grid container alignItems="center" mb={2.5} wrap="nowrap" className={classes.titleContainer}>
            <Icon className={classes.icon} />
            <Typography variant={'h6'} className={classes.title} ml={2}>
              {title}
            </Typography>
          </Grid>
          <Subtitle align="left" mb={4} ml={2} className={classes.subtitle}>
            {description}
          </Subtitle>
        </div>
      </CardContent>
    </Card>
  );
});

BenefitCard.propTypes = {
  title: PropTypes.string.isRequired,
  // icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
};

export default BenefitCard;
