import React, { useRef } from "react";
import {
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
  itemsWrapper: {
    minHeight: 400,
    padding: '8px 14px !important'
  },
  cardWrapper: {
    zIndex: 1,
    justifyContent: 'center',
    width: '100%',
    marginBottom: 0,
  },
  container: {
    paddingTop: '100px !important',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
  },
  card: {
    position: "relative",
    height: "100%",
    minHeight: 224,
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(to bottom, ${theme.colors.orange}, ${theme.colors.orangeGradientDark})`,
    opacity: 0,
  },
  cardContent: {
    position: "absolute",
    opacity: theme.constants.opacity,
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  title: {
    fontWeight: 500,
    fontSize: 18,

    [theme.breakpoints.down('sm')]: {
      fontSize: "1rem",
    },
  },
  description: {
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0.5,

    [theme.breakpoints.down('sm')]: {
      fontSize: "0.875rem",
    },
  },
}));


const FeaturesItemsMobile = ({title, GRID_SPACING, CARD_ITEMS}) => {
  const itemsContainerRef = useRef(null);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {title}
      <Grid container className={classes.cardWrapper} spacing={GRID_SPACING} alignItems="center" mb={8}>
        <Grid
          ref={itemsContainerRef}
          item
          container
          className={classes.itemsWrapper}
        >
          {CARD_ITEMS.map((cardItem, index) => {
            const { title, description, icon: Icon } = cardItem;
            return (
              <Grid key={index} item xs={6}>
                <Card elevation={0} className={classes.card}>
                  <div
                    className={classes.bg}
                  />
                  <CardContent
                    className={classes.cardContent}
                  >
                    <Icon className={classes.icon} color="secondary" />
                    <Typography color="secondary" className={classes.title} mb={1}>
                      {title}
                    </Typography>
                    <Typography color="secondary" className={classes.description}>
                      {description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default FeaturesItemsMobile;
