import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Layout from "../components/layout/layout";
import HomeHeroSection from "../components/home/home-hero-section";
import HomeBenefitsSection from "../components/home/home-benefits-section";
import HomeIndustriesSection from "../components/home/home-industries-section";
import HomeFeaturesSection from "../components/home/home-features-section";
import HomePricingSection from "../components/home/home-pricing-section";
import { canUseWebP } from "../utils/helpers";
import TestimonialsSection from "../components/home/testimonials-section";

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({
  ease: "power1.inOut",
});

const Home = ({ path }) => {
  const [imagesFormat, setImagesFormat] = useState();

  useEffect(() => {
    setImagesFormat(canUseWebP() ? 'webp' : 'png')
  }, []);

  return (
    <>
      {
        imagesFormat &&
        <Layout SeoProps={{
          hasPostfix: false,
          title: `Slate Pages | Easy Asset Tracking & Management App`,
          description: 'Track your assets, conduct routine inspections and more with ' +
            'the Slate Pages app, a fully customizable, easy to use, and secure ' +
            'solution. Get started today!',
        }}
                HeaderProps={{ path }}
        >
          <HomeHeroSection imagesFormat={imagesFormat}/>
          <HomeBenefitsSection imagesFormat={imagesFormat}/>
          <HomeIndustriesSection imagesFormat={imagesFormat}/>
          <TestimonialsSection/>
          <HomeFeaturesSection imagesFormat={imagesFormat}/>
          <HomePricingSection imagesFormat={imagesFormat}/>
        </Layout>
      }
    </>
  );
};

export default Home;
