import React, { useRef, useCallback, useState } from "react";
import { gsap } from "gsap";
import {
  Grid as MuiGrid,
  useMediaQuery,
} from "@material-ui/core";
import { styled, makeStyles, useTheme } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import ResponsiveImg from "../shared/responsive-img";
import FeatureCard from "./feature-card";
import { FEATURES_DURATION } from "../../utils/durations";
import cx from "classnames";
import QR_left from "../../assets/images/feature/checkerboard/QR_left.svg";
import QR_right from "../../assets/images/feature/checkerboard/QR_right.svg";
import { IMAGES } from "../../constants/images";
import './styles.css';
const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  itemsWrapper: {
    minHeight: 416,
    height: "100%",
    margin: "0 8px",
  },
  cardWrapper: {
    width: 976,
    zIndex: 1,
    display: "flex",
  },
  imageWrapper: {
    position: "relative",
    minHeight: 416,
    margin: "0 8px",
    backgroundColor: theme.colors.black,
    borderRadius: 15,

    [theme.breakpoints.down("xs")]: {
      padding: 0,
      overflow: "hidden",
      minHeight: 210,
      maxWidth: `calc(100% - 8px)`,
    },
  },
  image: {
    position: "absolute",
    transform: "translateY(-50%)",
    top: "50%",
    width: "100%",
    height: "100%",
    opacity: 0,
    visibility: "hidden",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  firstImage: {
    opacity: 1,
    visibility: "inherit",
  },
  showImage: {
    display: "inherit",
  },
  opacity: {
    opacity: theme.constants.opacity,
    marginBottom: "calc( 10px + 5vh)",
    zIndex: 1,
  },
  container: {
    position: "relative",
    paddingTop: "100px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    background: `linear-gradient(${theme.colors.blueGradientDark} 30%, ${theme.colors.blueGradientMedium} 100%)`,
  },
  hidden: {
    display: "none",
  },
  squaresContainer: {
    position: "absolute",
    height: "calc(100vh + 665px)",
    width: "100vw",
    top: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  hideContent: {
    position: "absolute",
    height: "100%",
    opacity: 0,
  },
  showContent: {
    opacity: 1,
  },
  rightSquares: {
    height: "fit-content",
    webkitMaskImage: "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
    maskImage: "linear-gradient(to left, #0000001a, rgba(0,0,0,0))",
  },
  leftSquares: {
    height: "fit-content",
    webkitMaskImage: "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
    maskImage: "linear-gradient(to right, #0000001a, rgba(0,0,0,0))",
  },
}));

const imagesSquaresList = [
  QR_left,
  QR_right,
];

const FeaturesItems = ({ sectionPositionY, title, CARD_ITEMS, imagesFormat }) => {
  const [step, setStep] = useState("step1");
  const cardsBgRefs = useRef([]);
  const cardsContentRefs = useRef([]);
  const imagesSquaresRefs = useRef([]);
  const containerRef = useRef(null);
  const itemsContainerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imagesRefs = useRef([]);
  const classes = useStyles();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const imageMain = IMAGES.home.features[imagesFormat];

  const setCardsBgRef = useCallback((node, index) => {
    cardsBgRefs.current[index] = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCardsContentRef = useCallback((node, index) => {
    cardsContentRefs.current[index] = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setImagesSquaresRef = useCallback((node) => {
    imagesSquaresRefs.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setContainerRef = useCallback((node) => {
    containerRef.current = node;
    if (!node) {
      return;
    }

    // ----- animation can't be removed => hidden container -------
    if (isLgScreen) {
      node.classList.remove(classes.hidden);
    } else {
      node.classList.add(classes.hidden);
    }

    const xOffset = !isSmScreen ? 488 : 0;


    //----- animation center context---------
    gsap
      .timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: containerRef.current,
          pin: true,
          scrub: 0.5,
          start: "center center",
          end: `+=${FEATURES_DURATION}`,
          onLeave: (data) => {
            containerRef.current.classList.remove('transform');
            containerRef.current.classList.add('removeTransform');
          },
          onEnter: (data) => {
            containerRef.current.classList.add('transform');
            containerRef.current.classList.remove('removeTransform');
          },
          onLeaveBack: (data) => {
            containerRef.current.classList.remove('transform');
          },
          onEnterBack: (data) => {
            containerRef.current.classList.add('transform');
            containerRef.current.classList.remove('removeTransform');
          },
        },
      })
      .add("step1")
      // --- item 1 ---
      .to(cardsBgRefs.current[0], { autoAlpha: 1 }, "step1")
      .to(cardsContentRefs.current[0], { autoAlpha: 1 }, "step1")
      .to(cardsBgRefs.current[0], { autoAlpha: 0 }, "step1+=2")
      .to(cardsContentRefs.current[0], { autoAlpha: 0.5 }, "step1+=2")
      .to(imagesRefs.current[1], { autoAlpha: 0 }, "step1+=1")
      .to(imagesRefs.current[2], { autoAlpha: 0 }, "step1+=1")
      .to(imagesRefs.current[3], { autoAlpha: 0 }, "step1+=1")
      .to(imagesRefs.current[4], { autoAlpha: 0 }, "step1+=1")
      .to(imagesRefs.current[5], { autoAlpha: 0 }, "step1+=1")
      .to(imagesRefs.current[6], { autoAlpha: 0 }, "step1+=1")
      .to(imagesRefs.current[7], { autoAlpha: 0 }, "step1+=1")
      // --- item 2 ---
      .to(cardsBgRefs.current[1], { autoAlpha: 1 }, "step1+=2")
      .to(cardsContentRefs.current[1], { autoAlpha: 1 }, "step1+=2")
      .to(imagesRefs.current[0], { autoAlpha: 0 }, "step1+=2")
      .to(imagesRefs.current[1], { autoAlpha: 1 }, "step1+=2")
      .to(cardsBgRefs.current[1], { autoAlpha: 0 }, "step1+=4")
      .to(cardsContentRefs.current[1], { autoAlpha: 0.5 }, "step1+=4")
      // --- item 3 ---
      .to(cardsBgRefs.current[2], { autoAlpha: 1 }, "step1+=4")
      .to(cardsContentRefs.current[2], { autoAlpha: 1 }, "step1+=4")
      .to(imagesRefs.current[1], { autoAlpha: 0 }, "step1+=4")
      .to(imagesRefs.current[2], { autoAlpha: 1 }, "step1+=4")
      .to(cardsBgRefs.current[2], { autoAlpha: 0 }, "step1+=6")
      .to(cardsContentRefs.current[2], { autoAlpha: 0.5 }, "step1+=6")
      // --- item 4 ---
      .to(cardsBgRefs.current[3], { autoAlpha: 1 }, "step1+=6")
      .to(cardsContentRefs.current[3], { autoAlpha: 1 }, "step1+=6")
      .to(imagesRefs.current[2], { autoAlpha: 0 }, "step1+=6")
      .to(imagesRefs.current[3], { autoAlpha: 1 }, "step1+=6")
      // .to(itemBgRefs.current[3], { autoAlpha: 0 }, "step1+=8")

      // --- transition ---
      .add("transition")
      .to(itemsContainerRef.current, { x: !isSmScreen ? xOffset : 0, duration: 2 }, "transition+=1")
      .to(imageContainerRef.current, { x: !isSmScreen ? -xOffset : 0, duration: 2 }, "transition+=1")
      .to(imagesRefs.current[3], { autoAlpha: 0, duration: 2 }, "transition+=1")
      .to(imagesRefs.current[4], { autoAlpha: 1, duration: 2 }, "transition+=1")
      .to(cardsBgRefs.current[3], { autoAlpha: 0, duration: 0 }, "transition+=2")
      .to(cardsContentRefs.current[3], { autoAlpha: 0.5, duration: 0 }, "transition+=2")
      .to(cardsBgRefs.current[0], {
        autoAlpha: 1, duration: 0,
        onStart: () => {
          setStep("step2");
        },
        onReverseComplete: () => {
          setStep("step1");
        },
      }, "transition+=2")
      .to(cardsContentRefs.current[0], { autoAlpha: 1, duration: 0 }, "transition+=2")

      .add("step2")
      // --- item 1 ---
      .to(cardsBgRefs.current[0], { autoAlpha: 1 }, "step2")
      .to(cardsContentRefs.current[0], { autoAlpha: 1 }, "step2")
      .to(cardsBgRefs.current[0], { autoAlpha: 0 }, "step2+=2")
      .to(cardsContentRefs.current[0], { autoAlpha: 0.5 }, "step2+=2")
      // --- item 2 ---
      .to(cardsBgRefs.current[1], { autoAlpha: 1 }, "step2+=2")
      .to(cardsContentRefs.current[1], { autoAlpha: 1 }, "step2+=2")
      .to(imagesRefs.current[4], { autoAlpha: 0 }, "step2+=2")
      .to(imagesRefs.current[5], { autoAlpha: 1 }, "step2+=2")
      .to(cardsBgRefs.current[1], { autoAlpha: 0 }, "step2+=4")
      .to(cardsContentRefs.current[1], { autoAlpha: 0.5 }, "step2+=4")
      // --- item 3 ---
      .to(cardsBgRefs.current[2], { autoAlpha: 1 }, "step2+=4")
      .to(cardsContentRefs.current[2], { autoAlpha: 1 }, "step2+=4")
      .to(imagesRefs.current[5], { autoAlpha: 0 }, "step2+=4")
      .to(imagesRefs.current[6], { autoAlpha: 1 }, "step2+=4")
      .to(cardsBgRefs.current[2], { autoAlpha: 0 }, "step2+=6")
      .to(cardsContentRefs.current[2], { autoAlpha: 0.5 }, "step2+=6")
      // --- item 4 ---
      .to(cardsBgRefs.current[3], { autoAlpha: 1 }, "step2+=6")
      .to(cardsContentRefs.current[3], { autoAlpha: 1 }, "step2+=6")
      .to(imagesRefs.current[6], { autoAlpha: 0 }, "step2+=6")
      .to(imagesRefs.current[7], { autoAlpha: 1 }, "step2+=6")
      .to(cardsBgRefs.current[3], { autoAlpha: 0 }, "step2+=8")
      .to(cardsContentRefs.current[3], { autoAlpha: 0.5 }, "step2+=8");

    //----- animation Squares images---------
    gsap
      .timeline({
        defaults: { duration: 2 },
        scrollTrigger: {
          trigger: node,
          scrub: 1,
          start: `top top`,
          end: `+=${FEATURES_DURATION}`,
        },
      })
      // --- item 1 ---
      .to(imagesSquaresRefs.current, { top: "-300px", duration: 1 });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmScreen, isLgScreen]);

  const onScroll = (step, index) => {
    // ---- 370px - height one item ----
    const top = step.slice(-1) < 2 ? (index + 1) * 370 + sectionPositionY :
      (index + 6) * 370 + sectionPositionY;

    window && window.scrollTo(
      {
        top,
        behavior: "smooth",
      },
    );
  };

  return (
    <div ref={setContainerRef} className={classes.container}
    >
      {title}
      <div className={classes.cardWrapper}>
        <Grid
          ref={itemsContainerRef}
          item
          xs={12}
          sm={6}
          container
          className={classes.itemsWrapper}
        >
          {CARD_ITEMS.map((cardItem, index) => {
            const { title, description, icon } = cardItem[step];
            return (
              <Grid key={index} item xs={6}>
                <FeatureCard
                  title={title}
                  description={description}
                  icon={icon}
                  ref={[
                    (node) => {
                      setCardsBgRef(node, index);
                    },
                    (node) => {
                      setCardsContentRef(node, index);
                    },
                  ]}
                  onClick={() => onScroll(step, index)}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid
          ref={imageContainerRef}
          item
          xs={12}
          sm={6}
          className={classes.imageWrapper}
        >
          {/* Can't use map due to unexpected render order */}
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[7] = node;
            }}
            src={imageMain.map.src}
            srcSet={`${imageMain.map.srcSet} 2x`}
            alt="Map"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[6] = node;
            }}
            src={imageMain.dashboard.src}
            srcSet={`${imageMain.dashboard.srcSet} 2x`}
            alt="Dashboard"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[5] = node;
            }}
            src={imageMain.privacy.src}
            srcSet={`${imageMain.privacy.srcSet} 2x`}
            alt="Privacy"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[4] = node;
            }}
            src={imageMain.search.src}
            srcSet={`${imageMain.search.srcSet} 2x`}
            alt="Search"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[3] = node;
            }}
            src={imageMain.changeTracking.src}
            srcSet={`${imageMain.changeTracking.srcSet} 2x`}

            alt="5_Search"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[2] = node;
            }}
            src={imageMain.assetTags.src}
            srcSet={`${imageMain.assetTags.srcSet} 2x`}
            alt="Asset Tags"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[1] = node;
            }}
            src={imageMain.mobileTablet.src}
            srcSet={`${imageMain.mobileTablet.srcSet} 2x`}
            alt="Mobile Tablet"
            className={classes.image}
          />
          <ResponsiveImg
            ref={(node) => {
              imagesRefs.current[0] = node;
            }}
            src={imageMain.customizableFields.src}
            srcSet={`${imageMain.customizableFields.srcSet} 2x`}
            alt="Customizable Fields"
            className={cx(classes.image, classes.firstImage)}
          />
        </Grid>
      </div>
      <div className={cx(classes.squaresContainer)} ref={setImagesSquaresRef}>
        {imagesSquaresList.map(((item, index) => (
          <img
            key={index}
            src={item}
            alt="Checkerboard"
            className={cx({
              [classes.leftSquares]: index === 0,
              [classes.rightSquares]: index === 1,
            })}
          />
        )))}
      </div>
    </div>
  );
};

export default FeaturesItems;
