import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Card as MuiCard,
  CardContent,
  Typography as MuiTypography,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: "100%",
    minHeight: 197,
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(to bottom, ${theme.colors.orange}, ${theme.colors.orangeGradientDark})`,
    opacity: 0,
  },
  cardContent: {
    position: "absolute",
    opacity: theme.constants.opacity,
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  title: {
    fontWeight: 500,
    fontSize: '1rem',
    fontFamily: "'Montserrat', sans-serif",

    [theme.breakpoints.down('sm')]: {
      fontSize: "1rem",
    },
  },
  description: {
    fontSize: '.85rem',
    fontWeight: 300,
    letterSpacing: 0.5,

    [theme.breakpoints.down('sm')]: {
      fontSize: "0.875rem",
    },
  },
}));

const FeatureCard = forwardRef(
  ({ onClick, title, description, icon: Icon, isFirst }, [setBgRef, setContentRef]) => {
    const classes = useStyles();
    return (
      <Card elevation={0} className={classes.card} onClick={onClick}>
        <div
          ref={setBgRef}
          className={classes.bg}
        />
        <CardContent
          ref={setContentRef}
          className={classes.cardContent}
        >
          <Icon className={classes.icon} color="secondary" />
          <Typography color="secondary" className={classes.title} mb={1}>
            {title}
          </Typography>
          <Typography color="secondary" className={classes.description}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    );
  },
);

FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
};

FeatureCard.displayName = "ScrollCard";

export default FeatureCard;
