export const changeClickScrollHeight = node => {
  const windowScrollY = window.pageYOffset || document.documentElement.scrollTop;
  const currentItemScrollY = node && node.getBoundingClientRect().top;
  return windowScrollY + currentItemScrollY
}

export const copyToTheClipboard = (val) => {
  const replacedResult = val.replaceAll('test', '')
  let dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = replacedResult;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export const  canUseWebP = () => {
  let elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp') && elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

function capitalizeFirstLetter(str) {
  return str
    .toLowerCase()
    .replace(/\w/, firstLetter => firstLetter.toUpperCase());
}

export function updateSnakeCaseToCapitalisedText(str) {
  return str.split("_").map(word => capitalizeFirstLetter(word)).join(" ");
}

