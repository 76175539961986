import React, { useRef, useCallback, useState, useEffect } from "react";
import { gsap } from "gsap";
import Typed from "react-typed";
import { Link } from "gatsby";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import cx from "classnames";
import ResponsiveImg from "../shared/responsive-img";
import Section from "../shared/section";
import Subtitle from "../shared/subtitle";
import { LINKS } from "../../constants/links";
import { IMAGES } from "../../constants/images";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  section:{
    paddingTop: 50,
    paddingBottom: 40,
    [theme.breakpoints.down(510)]: {
      paddingTop: 20,
    },
  },
  shadowContainer: {
    boxShadow: '-2px 20px 44px -10px rgba(122,122,122,0.62)',
    backgroundColor: 'rgb(0 0 0 / 15%)',
  },
  imageWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: 798,
    margin: "0 auto",
  },
  image: {
    position: "absolute",
    backgroundColor: `${theme.colors.greyShadow}`,
    left: 0,
    top: 0,
    display:'block',
    maxHeight: '426.462px',
    height: '51vw',
  },
  imageRoot:{
    position: "relative",
  },
  phoneWrapper: {
    width: '41%',
    position: "absolute",
    maxWidth: 320,
    height: "140%",
    top: "-20%",
    right: 0,
    overflow: "hidden",
    perspective: "34px",
  },
  iPhoneImage: {
    height: "100%",
    maxWidth: "90%",
  },
  slateImgWrapper: {
    position: "absolute",
    top: "-0.5%",
    left: "20.5%",
    width: "69%",
    height: "99%",
    transform: "rotateY(1deg) translateZ(-3px)",
    borderRadius: "5%",
    overflow: "hidden",
    zIndex: -1,

    [theme.breakpoints.down(750)]: {
      transform: "rotateY(1.1deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(680)]: {
      transform: "rotateY(1.3deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(600)]: {
      transform: "rotateY(1.5deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(560)]: {
      transform: "rotateY(1.6deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(510)]: {
      transform: "rotateY(1.7deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(480)]: {
      transform: "rotateY(1.85deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(450)]: {
      transform: "rotateY(2deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(420)]: {
      transform: "rotateY(2.2deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(400)]: {
      transform: "rotateY(2.35deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(380)]: {
      transform: "rotateY(2.6deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(365)]: {
      transform: "rotateY(2.85deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(330)]: {
      transform: "rotateY(3deg) translateZ(-3px)",
    },
    [theme.breakpoints.down(300)]: {
      transform: "rotateY(3.3deg) translateZ(-3px)",
    },
  },
  qrImage: {
    position: "absolute",
    left: 0,
    height: "100%",
    imageRendering: "-webkit-optimize-contrast",
    // imageRendering: "crisp-edges",
  },
  slateImage: {
    top: "100%",
  },
  scannerLine: {
    position: "absolute",
    height: 1,
    backgroundColor: theme.palette.common.white,
    width: '56%',
    top: '36.7%',
    left: '22%',
    opacity: 0,
  },
  typingContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 80,
      marginBottom: 40,
    },
  },
  typedContainer: {
    display: 'flex',
  },
  typedWrapper: {
    fontSize: "3.12rem",
    fontFamily: `'Permanent Marker', cursive`,
    color: theme.colors.orange,
    position: "relative",
    bottom: 7,
    lineHeight: 1,
    borderBottom: `1px solid rgb(194 194 194)`,
    width: 380,

    [theme.breakpoints.down(679)]: {
      display: 'flex',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: "2.5rem",
      width: 315,
    },
  },
  contactLink: {
    color: theme.colors.orange,
    textDecoration: 'none',
    fontSize: '1rem',
    "&:hover": {
      textDecoration: 'underline',
    },
  },
  title: {
    marginBottom: 10,

    [theme.breakpoints.down('xs')]: {
      fontSize: "2.5rem",
    },
  },
}));

let timeLine;
const DELAY = 10;
const TYPED = ["Maintenance", "Inspections", "Manufacturing", "Health Checks"];
const TYPE_SPEED = 80;
const BACK_SPEED = 40;

const HomeHeroSection = ({imagesFormat}) => {
  const [typedPos, setTypedPos] = useState(null);
  const classes = useStyles();
  const sectionRef = useRef(null);
  const typedRef = useRef(null);
  const imagesRefs = useRef([]);
  const qrImagesRefs = useRef([]);
  const scannerLineRef = useRef(null);
  const qrScannerImageRef = useRef(null);
  const slateImageRef = useRef([]);
  const imageMain = IMAGES.home.hero.main[imagesFormat];
  const imagePhone = IMAGES.home.hero.phone[imagesFormat];
  const imageQr = IMAGES.home.hero.qrImage[imagesFormat];
  const imageSlateView = IMAGES.home.hero.slateView[imagesFormat];
  const imageSlate = IMAGES.home.hero.slate[imagesFormat];
  const [timer, setTimer] = useState(0);
  const [finishedTypeAnimation, setFinishedTypeAnimation] = useState(false);

  useEffect(() => {
    let _timer = 0;
    setInterval(() => {
      setTimer(_timer + 1);
      _timer += 1;
    }, 1000);

    window.addEventListener('scroll', () => {
      _timer = 0;
      handleScroll();
    });
    return window.removeEventListener('scroll', () => {
      clearInterval();
      handleScroll();
    });
  }, []);

  useEffect(() => {
    const paused = timeLine && timeLine.paused();
    if(timer === 0 && paused) {
      timeLine.play();
      typedRef.current.start();
      setFinishedTypeAnimation(!finishedTypeAnimation);
    }
  }, [timer]);

  const handleScroll = () => {
    setTimer(0);
  }

  const onStringTyped = (arrayPos, self) => {
    if(timer > 15 && finishedTypeAnimation) {
      timeLine.pause()
      typedRef.current.stop();
    }

    if (arrayPos !== typedPos) {
      self.stop();
      setTypedPos(arrayPos);
    }
    setFinishedTypeAnimation(!finishedTypeAnimation);
  }

  const setSectionRef = useCallback((node) => {
    const MOBILE_SCREEN_HEIGHT = '-100%';

    sectionRef.current = node;
    if (node) {
      if(timeLine){
        timeLine.restart();
      }
      gsap.to(node, {
        scrollTrigger: {
          trigger: node,
          start: "50% top",
          end: "bottom top",
          scrub: 0.5,
        },
        autoAlpha: 0,
      });

      setTimeout(() => {
        timeLine = gsap
          .timeline({
            repeat: -1,
            // repeatDelay: DELAY,
            // delay: DELAY,
          })
          .add("start")
          // --- image 1 ---
          .to(scannerLineRef.current, { opacity: 1, duration: 0.5 }, "start+=0.5")
          .to(scannerLineRef.current, {
            y: () => qrScannerImageRef.current && qrScannerImageRef.current.height * 0.266,
            duration: 2 })
          .to(scannerLineRef.current, { opacity: 0, duration: 0.5 })
          .to(scannerLineRef.current, { y: 0, duration: 0 })
          .to(slateImageRef.current[0], { duration: .8, ease: "expo.out", y: MOBILE_SCREEN_HEIGHT })
          .to(slateImageRef.current[0], {
            y: 0,
            duration: 1,
            delay: 3.5,
            onComplete: () => {
              setTypedPos(0);
              typedRef.current.start();
            },
          })
          .to(imagesRefs.current[0],{autoAlpha: 0},`start+=${DELAY}`)
          .to(qrImagesRefs.current[0], { autoAlpha: 0 }, `start+=${DELAY}`)
          // --- image 2 ---
          .to(
            scannerLineRef.current,
            { opacity: 1, duration: 0.5 },
            `start+=${DELAY + 0.5}`,
          )
          .to(scannerLineRef.current, {
            y: () => qrScannerImageRef.current && qrScannerImageRef.current.height * 0.266,
            duration: 2 })
          .to(scannerLineRef.current, { opacity: 0, duration: 0.5 })
          .to(scannerLineRef.current, { y: 0, duration: 0 })
          .to(slateImageRef.current[1], { duration: .8, ease: "expo.out", y: MOBILE_SCREEN_HEIGHT })
          .to(slateImageRef.current[1], {
            y: 0,
            duration: 1,
            delay: 3.5,
            onComplete: () => {
              setTypedPos(1);
              typedRef.current.start();
            },
          })
          .to(imagesRefs.current[1],{autoAlpha: 0},`start+=${DELAY * 2}`)
          .to(qrImagesRefs.current[1], { autoAlpha: 0 }, `start+=${DELAY * 2}`)
          // --- image 3 ---
          .to(
            scannerLineRef.current,
            { opacity: 1, duration: 0.5 },
            `start+=${DELAY * 2 + 0.5}`,
          )
          .to(scannerLineRef.current, {
            y: () => qrScannerImageRef.current && qrScannerImageRef.current.height * 0.266,
            duration: 2 })
          .to(scannerLineRef.current, { opacity: 0, duration: 0.5 })
          .to(scannerLineRef.current, { y: 0, duration: 0 })
          .to(slateImageRef.current[2], { duration: .8, ease: "expo.out", y: MOBILE_SCREEN_HEIGHT })
          .to(slateImageRef.current[2], {
            y: 0,
            duration: 1,
            delay: 3.5,
            onComplete: () => {
              setTypedPos(2);
              typedRef.current.start();
            },
          })
          .to(imagesRefs.current[2],{autoAlpha: 0},`start+=${DELAY * 3}`)
          .to(qrImagesRefs.current[2], { autoAlpha: 0 }, `start+=${DELAY * 3}`)
          // --- image 4 ---
          .to(
            scannerLineRef.current,
            { opacity: 1, duration: 0.5 },
            `start+=${DELAY * 3 + 0.5}`,
          )
          .to(scannerLineRef.current, {
            y: () => qrScannerImageRef.current && qrScannerImageRef.current.height * 0.266,
            duration: 2 })
          .to(scannerLineRef.current, { opacity: 0, duration: 0.5 })
          .to(scannerLineRef.current, { y: 0, duration: 0 })
          .to(slateImageRef.current[3], { duration: .8, ease: "expo.out", y: MOBILE_SCREEN_HEIGHT })
          .to(slateImageRef.current[3], {
            y: 0,
            duration: 1,
            delay: 3.5,
            onComplete: () => {
              setTypedPos(3);
              typedRef.current.start();
            },
          })
          .to(imagesRefs.current[0],{autoAlpha: 1},`start+=${DELAY * 4}`)
          .to(qrImagesRefs.current[0], { autoAlpha: 1 }, `start+=${DELAY * 4}`);
      }, 2200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      ref={setSectionRef}
      container
      direction="column"
      alignItems="center"
      pt={10}
      pb={15}
      innerClassName={classes.section}
    >
      <div className={classes.imageWrapper}>
        <div className={classes.shadowContainer}>
          <ResponsiveImg
            src={imageMain.clipboard.src}
            srcSet={`${imageMain.clipboard.srcSet} 2x`}
            alt={TYPED[3]}
            className={cx(classes.image, classes.imageRoot)}
            ref={(node) => {
              imagesRefs.current[3] = node;
            }}
          />
          <ResponsiveImg
            src={imageMain.manufacturing.src}
            srcSet={`${imageMain.manufacturing.srcSet} 2x`}
            alt={TYPED[2]}
            className={classes.image}
            ref={(node) => {
              imagesRefs.current[2] = node;
            }}
          />
          <ResponsiveImg
            src={imageMain.SCBA.src}
            srcSet={`${imageMain.SCBA.srcSet} 2x`}
            alt={TYPED[1]}
            className={classes.image}
            ref={(node) => {
              imagesRefs.current[1] = node;
            }}
          />
          <ResponsiveImg
            src={imageMain.backhoe.src}
            srcSet={`${imageMain.backhoe.srcSet} 2x`}
            alt={TYPED[0]}
            className={classes.image}
            ref={(node) => {
              imagesRefs.current[0] = node;
            }}
          />
        </div>
        <div className={classes.phoneWrapper}>
          <ResponsiveImg
            src={imagePhone.src}
            alt="iPhone"
            className={classes.iPhoneImage}
          />
          <div className={classes.slateImgWrapper}>
            <ResponsiveImg
              ref={(node) => {
                qrImagesRefs.current[3] = node;
              }}
              src={imageQr.clipboard.src}
              alt="QR code"
              className={classes.qrImage}
            />
            <ResponsiveImg
              ref={(node) => {
                qrImagesRefs.current[2] = node;
              }}
              src={imageQr.manufacturing.src}
              alt="QR code"
              className={classes.qrImage}
            />
            <ResponsiveImg
              ref={(node) => {
                qrImagesRefs.current[1] = node;
              }}
              src={imageQr.SCBA.src}
              alt="QR code"
              className={classes.qrImage}
            />
            <ResponsiveImg
              ref={(node) => {
                qrImagesRefs.current[0] = node;
              }}
              src={imageQr.backhoe.src}
              alt="QR code"
              className={classes.qrImage}
            />
            <ResponsiveImg
              ref={qrScannerImageRef}
              src={imageSlateView.src}
              srcSet={`${imageSlateView.srcSet} 2x`}
              alt="Slate Scanner"
              className={classes.qrImage}
            />
            <ResponsiveImg
              ref={(node) => {
                slateImageRef.current[3] = node;
              }}
              src={imageSlate.clipboard.src}
              srcSet={`${imageSlate.clipboard.srcSet} 2x`}
              alt="Slate Content Clipboard"
              className={cx(classes.qrImage, classes.slateImage)}
            />
            <ResponsiveImg
              ref={(node) => {
                slateImageRef.current[2] = node;
              }}
              src={imageSlate.manufacturing.src}
              srcSet={`${imageSlate.manufacturing.srcSet} 2x`}
              alt="Slate Content Manufacturing"
              className={cx(classes.qrImage, classes.slateImage)}
            />
            <ResponsiveImg
              ref={(node) => {
                slateImageRef.current[1] = node;
              }}
              src={imageSlate.SCBA.src}
              srcSet={`${imageSlate.SCBA.srcSet} 2x`}
              alt="Slate Content SCBA"
              className={cx(classes.qrImage, classes.slateImage)}
            />
            <ResponsiveImg
              ref={(node) => {
                slateImageRef.current[0] = node;
              }}
              src={imageSlate.backhoe.src}
              srcSet={`${imageSlate.backhoe.srcSet} 2x`}
              alt="Slate Content Backhoe"
              className={cx(classes.qrImage, classes.slateImage)}
            />
            <div ref={scannerLineRef} className={classes.scannerLine} />
          </div>
        </div>
      </div>
      <Grid container mt={15} mb={2}
            alignItems="center"
            justify="center"
            classes={{
              root: classes.typingContainer,
            }}>
        <Typography variant="h2" mr={1} classes={{
          root: classes.title,
        }}>
          Slate your
        </Typography>
        <div className={classes.typedContainer}>
          <div className={classes.typedWrapper}>
            <Typed
              typedRef={(typed) => {
                typedRef.current = typed;
              }}
              strings={TYPED}
              typeSpeed={TYPE_SPEED}
              backSpeed={BACK_SPEED}
              // backDelay={BACK_DELAY}
              smartBackspace={true}
              // stopped
              loop
              onStringTyped={onStringTyped}
            />
          </div>
          <Typography variant="h2" ml={0.5}>
            .
          </Typography>
        </div>
      </Grid>
      <Subtitle mb={4} className={classes.subtitle}>
        Start tracking your assets from your smartphone with the easy-to-use
        Slate Pages app! Our mobile asset tracking system uses QR tags (we call
        them Slates) to access the information linked to your asset.
      </Subtitle>
      <Grid container direction="column" justify="center" alignItems="center">
        <Link to={LINKS.contact} className={classes.contactLink}>
          Questions? Contact us.
        </Link>
      </Grid>
    </Section>
  );
};

export default HomeHeroSection;
