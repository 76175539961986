import React, { useRef } from "react";
import PropTypes from "prop-types";
import { gsap } from "gsap";
import {
  Card,
  CardMedia,
  CardContent,
  Typography as MuiTypography, Link as MuiLink,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import CustomButton from "../shared/custom-button";
import { Link } from "gatsby";

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    position: "relative",
    height: 300,

    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
  },
  cardGradient: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `linear-gradient(to bottom, transparent, rgba(0,0,0,0.5))`,
  },
  cardContent: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
  cardContentBg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    opacity: 0,
  },
  descriptionWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: 600,
    letterSpacing: 1.5,
    margin: 0,
  },
  description: {
    fontSize: 14,
  },
}));

const DURATION = 0.5;

const IndustryCard = ({ title, image, description, href }) => {
  const cardRef = useRef(null);
  const contentBgRef = useRef(null);
  const descriptionRef = useRef(null);
  const classes = useStyles();

  const animateIn = () => {
    gsap.to(cardRef.current, { duration: DURATION, y: -20 });
    gsap.to(contentBgRef.current, { duration: DURATION, opacity: 0.8 });
    gsap.to(descriptionRef.current, { duration: DURATION, top: "0%" });
  };

  const animateOut = () => {
    gsap.to(cardRef.current, { duration: DURATION, y: 0 });
    gsap.to(contentBgRef.current, { duration: DURATION, opacity: 0 });
    gsap.to(descriptionRef.current, { duration: DURATION, top: "70%" });
  };

  return (
    <Card
      ref={cardRef}
      elevation={5}
      onMouseEnter={animateIn}
      onMouseLeave={animateOut}
    >
      <CardMedia className={classes.cardMedia} image={image}>
        <div className={classes.cardGradient} />
        <CardContent className={classes.cardContent}>
          <div ref={contentBgRef} className={classes.cardContentBg} />
          <div ref={descriptionRef} className={classes.descriptionWrapper}>
            <Typography
              align="center"
              color="secondary"
              className={classes.title}
              mx={2}
            >
              {title}
            </Typography>
            <Typography
              align="center"
              color="secondary"
              className={classes.description}
            >
              {description}
            </Typography>
            <MuiLink
              component={Link}
              className={classes.buttonDemo}
              color="secondary"
              underline="none"
              to={href}
            >
              <CustomButton variant="contained" color="secondary">
                Learn More
              </CustomButton>
            </MuiLink>
          </div>
        </CardContent>
      </CardMedia>
    </Card>
  );
};

IndustryCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default IndustryCard;
