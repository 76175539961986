import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "../shared/section";
import Carousel from "react-material-ui-carousel";
import AmyGreweArborAesthetics from "../../assets/images/testimonials-section/amy-grewe-arbor-aesthetics.jpeg";
import StevenTycAssistantFireChiefMiddlefieldVolFireCompany
  from "../../assets/images/testimonials-section/steven-tyc-assistant-fire-chief-middlefield-vol-fire-company.jpeg";
import KevinMoreiraIPCLydon from "../../assets/images/testimonials-section/kevin-moreira-IPC-lydon.png";
import Subtitle from "../shared/subtitle";
import Icon from "@material-ui/core/Icon";

const DefaultSettingsT = {
  autoPlay: true,
  animation: "fade",
  indicators: true,
  duration: 500,
  navButtonsAlwaysVisible: true,
  navButtonsAlwaysInvisible: false,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: 70,
    marginRight: 70,
  },
  cardIcon: {
    height: 100,
    width: 'auto',
  },
  cardIconImg: {
    height: '100%',
    maxWidth: '100%',
  },
  cardTitle: {
    fontSize: "1rem",
    maxWidth: 700,
    marginBottom: 20,
    fontWeight: "bold",
  },
  cardText: {
    fontSize: "1rem",
    maxWidth: 700,
    marginBottom: 20,
  },
}));

const TestimonialsSection = () => {
  const classes = useStyles();

  return (
    <Section
      sectionClassName={classes.section}
    >
      <Carousel
        className="Example"
        {...DefaultSettingsT}
        navButtonsProps={{ style: { backgroundColor: "transparent", color: "#f15a29" }, borderRadius: 0 }}
      >
        {
          items.map(({ text, icon, title }) => {
            return <div className={classes.card} key={`${title}`}>
              <Subtitle mb={8} className={classes.cardText}>
                "{text}"
              </Subtitle>
              <Subtitle mb={8} className={classes.cardTitle}>
                {title}
              </Subtitle>
              <Icon classes={{
                root: classes.cardIcon,
              }}>
                <img alt="edit" src={icon} className={classes.cardIconImg} />
              </Icon>
            </div>;
          })
        }
      </Carousel>
    </Section>
  );
};

export default TestimonialsSection;

const items = [
  {
    title: "Amy Grewe, Arbor Aesthetics",
    text: "Slate Pages has been integral in the TCIA Accreditation process for our company. Daily DOT inspections were completely new to us and the paper DOT inspection logs felt overwhelming. I wanted to avoid giving our crew \"one more piece of paperwork.\" Each crew member is able to down the app on their own device and see who last inspected a vehicle or piece of equipment. It really is as easy as checking boxes on an app and that helped to establish a routine for our crews. As we continue to scale our business, I am sure there are many other ways we can utilize and customize the platform to work for us. Support and training have been easily available to me which is important for a growing and ever-changing business.",
    icon: AmyGreweArborAesthetics,
  },
  {
    title: "Steven Tyc, Assistant Fire Chief Middlefield Vol. Fire Company",
    text: "Between all the different requirements fire departments have today, a huge part of what we do is documentation. This was a huge help for us by finding a simple, fast, and reliable way for our members to complete the necessary inspections. Other systems we looked at had recurring costs and were subscription based. Slate Pages will save you money, no doubt, as it is a one time cost. As a smaller volunteer department, we value the time our members sacrifice every day and finding a system that gets them back to their families quicker is priceless!",
    icon: StevenTycAssistantFireChiefMiddlefieldVolFireCompany,
  },
  {
    title: "Kevin Moreira, IPC Lydon",
    text: "The Slate Pages QR tags were just what I was looking for to bridge the gap and lead the company to a more technology driven approach in regards to safety…I was intrigued with the level of detail that could be added to each item and how they could be customized to our specific needs. Other products I had been looking into were very cookie cutter and generic for the different types of safety equipment we had in our inventory. Slates Pages works great for our needs and we are looking forward to start applying Slate Pages QR tags to more safety equipment soon.",
    icon: KevinMoreiraIPCLydon,
  },
];
