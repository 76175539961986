import React, { useCallback, useRef } from "react";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Card as MuiCard,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Icon1 from "../../assets/images/pricing/Pricing icon 1.svg"
import Icon2 from "../../assets/images/pricing/Pricing icon 2.svg"
import Icon3 from "../../assets/images/pricing/Pricing icon 3.svg"
import Section from "../shared/section";
import Subtitle from "../shared/subtitle";
import ResponsiveImg from "../shared/responsive-img";
import Icon from "@material-ui/core/Icon";
import cx from "classnames";
import { IMAGES } from "../../constants/images";
const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
  mainImg: {
    position: "relative",
    bottom: theme.spacing(5),
    marginLeft: '7%',
    width: '93%',
  },
  moveToTop: {
    marginTop: -35,
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
    },
  },
  subtitle: {
    maxWidth: 700,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    maxWidth: 355,
    minWidth: 260,
    margin: 'auto',
  },
  cardIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: 6,
  },
  cardIconImg: {
    height: '100%',
    maxWidth: '100%',
  },
  cardTitle: {
    color: theme.colors.orange,
    fontSize: 20,
    fontWeight: 600,
  },
}));

const items = [
  {
    icon: Icon1,
    line1: "Starter packs for as",
    line2: "low as $34.99",
  },
  {
    icon: Icon2,
    line1: "All features included",
    line2: "no hidden fees",
  },
  {
    icon: Icon3,
    line1: "Add slates as you",
    line2: "need them",
  },
];

const HomePricingSection = ({imagesFormat}) => {
  const cardsRefs = useRef([]);
  const classes = useStyles();
  const imageMain = IMAGES.home.pricing[imagesFormat];


  const setCardsRef = useCallback((node, index) => {
    cardsRefs.current[index] = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <ResponsiveImg
        src={imageMain.src}
        srcSet={`${imageMain.srcSet} 2x`}
        alt="Slate Pages"
        className={classes.mainImg}
      />
      <Typography variant="h4" align="center" className={cx(classes.title, classes.moveToTop)}>
        Scalable pricing to
      </Typography>
      <Typography variant="h4" align="center" mb={1} className={classes.title}>
        fit your solution.
      </Typography>
      <Subtitle mb={8} className={classes.subtitle}>
        Slate Pages is the most customizable & user-friendly asset tracking solution available,
        no matter how many assets you have to manage.
      </Subtitle>

      <Grid container spacing={4} mb={5} justify={'center'}>
        {items.map(({ line1, line2, icon }, index) => (
          <Grid
            key={`${line1}${line2}`}
            item
            xs={12}
            sm={6}
            md={4}
            ref={(node) => {
              setCardsRef(node, index);
            }}
          >
            <Card elevation={0} className={classes.card} p={3}>
              <Icon classes={{
                root: classes.cardIcon,
              }}>
                <img alt="edit" src={icon} className={classes.cardIconImg}/>
              </Icon>
              <Typography
                align="center"
                className={classes.cardTitle}
                mt="auto"
              >
                {line1}
              </Typography>
              <Typography align="center" className={classes.cardTitle}>
                {line2}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default HomePricingSection;
