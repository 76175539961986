import React, { useRef, useCallback, useState, useEffect } from "react";
import { gsap } from "gsap";
import { Grid as MuiGrid, Link as MuiLink, Typography as MuiTypography, useMediaQuery } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Section from "../shared/section";
import Subtitle from "../shared/subtitle";
import CustomButton from "../shared/custom-button";
import FeaturesItems from "./features-items";
import FeaturesItemsMobile from "./features-items-mobile";
import theme from "../../utils/theme";
import Icon1 from "../../assets/svgs/features/icon1.svg";
import Icon5 from "../../assets/svgs/features/icon5.svg";
import Icon2 from "../../assets/svgs/features/icon2.svg";
import Icon6 from "../../assets/svgs/features/icon6.svg";
import Icon3 from "../../assets/svgs/features/icon3.svg";
import Icon7 from "../../assets/svgs/features/icon7.svg";
import Icon4 from "../../assets/svgs/features/icon4.svg";
import Icon8 from "../../assets/svgs/features/icon8.svg";
import cx from "classnames";
import { changeClickScrollHeight } from "../../utils/helpers";
import { BENEFITS_DURATION } from "../../utils/durations";
import { LINKS } from "../../constants/links";
import { Link } from "gatsby";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  section: {
    padding: 0,
    background: `linear-gradient(${theme.colors.blueGradientDark} -0%, #011935 100%)`
  },
  sectionNode: {
    width: '100%',
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
    overflow: 'hidden',
  },
  opacity: {
    opacity: theme.constants.opacity,
  },
  opacityTitle: {
    opacity: theme.constants.opacity,
    marginBottom: 'calc( 10px + 5vh)',
    zIndex: 1,
    margin: '0 30px',
    width: 'calc(100% - 60px)',
  },
  mainTitle: {
    margin: '0 50px 12px'
  },
  footerContainer: {
    margin: '80px 30px 0',
    width: 'calc(100% - 60px)',
    height: 190,
  }
}));


const CARD_ITEMS = [
  {
    icon: Icon1,
    title: "Customizable data fields",
    description: "Format your slate to meet your needs",
  },
  {
    icon: Icon5,
    title: "Powerful search engine",
    description:
      "All your slate data indexed for quick access, from the app or dashboard",
  },
  {
    icon: Icon2,
    title: "iOS & Android supported",
    description:
      "Use The Slate Pages cloud-based asset tracking platform on your own mobile or tablet device",
  },
  {
    icon: Icon6,
    title: "Multi-level privacy settings",
    description: "Control who can see and edit your data, field by field",
  },

  {
    icon: Icon3,
    title: "Asset tags with QR codes",
    description:
      "Slates (QR tags) are customizable for your interior or exterior applications",
  },
  {
    icon: Icon7,
    title: "Desktop access with Dashboard",
    description:
      "Create advanced views of your data for easy filtering and sorting",
  },
  {
    icon: Icon4,
    title: "Change-tracking built right in",
    description:
      "View historical changes to your slates with user and timestamp detail",
  },
  {
    icon: Icon8,
    title: "Interactive Mapping",
    description:
      "View locations, get directions, and see all your assets on customizable map views",
  },
];
const CARD_ITEMS_WITH_STEPS = [
  {
    step1: CARD_ITEMS[0],
    step2: CARD_ITEMS[1],
  },
  {
    step1: CARD_ITEMS[2],
    step2: CARD_ITEMS[3],
  },
  {
    step1: CARD_ITEMS[4],
    step2: CARD_ITEMS[5],
  },
  {
    step1: CARD_ITEMS[6],
    step2: CARD_ITEMS[7],
  },
];
const GRID_SPACING = 2;


const HomeFeaturesSection = ({imagesFormat}) => {
  const sectionRef = useRef(null);
  const classes = useStyles();
  const [sectionPositionY, setSectionPositionY] = useState(0);
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const setSectionRef = useCallback((node) => {
    if (!node) {
      return;
    }

    node && setSectionPositionY(changeClickScrollHeight(node) + BENEFITS_DURATION);
    sectionRef.current = node;
    if (node) {
      gsap.timeline({
        scrollTrigger: {
          trigger: node,
          end: () => `+=${node.offsetHeight}`,
          scrub: 0.5,
          id: "features-container",
        },
        autoAlpha: 0,
      });
    }
  }, []);

  useEffect(() => {
    const changeScrollHeight = () => {
      setSectionPositionY(changeClickScrollHeight(sectionRef.current))
    }
    window.addEventListener('resize', () => changeScrollHeight())
    return window.removeEventListener('resize', () => changeScrollHeight())
  }, []);

  const TitleRender = () => {
    return <>
      <Typography variant="h4" align="center"
                  color="secondary" mb={1}
                  className={cx(classes.title, {
                    [classes.mainTitle]: !isLgScreen,
                  })}
      >
        Game-Changing Features
      </Typography>
      <Subtitle mb={15} className={classes.opacityTitle} color="secondary">
        Software built with innovative ways to track & manage your assets.
      </Subtitle>
    </>;
  }

  const FooterRenderer = () => <Grid container alignItems="center"
                                     direction="column" mt={12}
                                     className={classes.footerContainer}
  >
    <Subtitle color="secondary" className={classes.opacity} mb={4}>
      Slate Pages has many features that can be tailored to solve specific
      challenges you face day to day. Let us show you how!
    </Subtitle>
    <MuiLink
      component={Link}
      className={classes.buttonDemo}
      color="secondary"
      underline="none"
      to={LINKS.contact + '?reason=request_a_demo&subject=Request+a+Slate+Pages+Demo'}
    >
      <CustomButton variant="contained" color="primary">
        Request a Demo
      </CustomButton>
    </MuiLink>
  </Grid>

  return (
    <Section
      pt={12}
      pb={15}
      sectionClassName={classes.section}
      innerClassName={classes.sectionNode}
      ref={setSectionRef}
      id={'container-feature'}
    >
      <FeaturesItems
        sectionPositionY={sectionPositionY}
        title={<TitleRender/>}
        GRID_SPACING={GRID_SPACING}
        CARD_ITEMS={CARD_ITEMS_WITH_STEPS}
        imagesFormat={imagesFormat}
      />
      {
        !isLgScreen &&
        <FeaturesItemsMobile
          title={<TitleRender/>}
          GRID_SPACING={GRID_SPACING}
          CARD_ITEMS={CARD_ITEMS}
        />
      }
      <FooterRenderer/>
    </Section>
  );
};

export default HomeFeaturesSection;
