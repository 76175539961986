import React, { useRef, useCallback, useState, useEffect } from "react";
import { gsap } from "gsap";
import {
  Typography as MuiTypography,
  Grid as MuiGrid, useMediaQuery,
} from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Section from "../shared/section";
import Subtitle from "../shared/subtitle";
import ResponsiveImg from "../shared/responsive-img";
import BenefitCard from "./benefit-card";
import IPhoneImg from "../../assets/images/benefits/Slate_iPhone.png";
import { BENEFITS_DURATION } from "../../utils/durations";
import Icon1 from "../../assets/svgs/benefits/Icon1.svg";
import Icon2 from "../../assets/svgs/benefits/Icon2.svg";
import Icon3 from "../../assets/svgs/benefits/Icon3.svg";
import cx from "classnames";
import theme from "../../utils/theme";
import { changeClickScrollHeight } from "../../utils/helpers";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { IMAGES } from "../../constants/images";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  imagesWrapper: {
    position: "relative",
    height: 720,
    width: 390,
    maxWidth: 390,

    [theme.breakpoints.down('sm')]: {
      height: 554.4,
      width: 300,
      display: 'none',
    },
  },
  slateImgWrapper: {
    position: "absolute",
    zIndex: -1,
    top: "5.8%",
    left: "15%",
    width: "74%",
    height: "86%",
    overflow: "hidden",
    backgroundColor: 'black'
  },
  phoneImage: {
    height: "100%",
    width: "100%",
  },
  contentWrapper: {
    [theme.breakpoints.down(400)]: {
      paddingTop: 20,
    },
    [theme.breakpoints.down(380)]: {
      paddingTop: 0,
    },
  },
  mainContainer: {
    paddingTop: 30,
    margin: '0 auto',
    maxWidth: 960,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
      paddingTop: 0,
    },
  },
  mainContainerMobile: {
    paddingTop: 100,
    margin: '0 auto',
    maxWidth: 960,
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
    },
  },
  titleMobile: {
    textAlign: 'center',
    marginLeft: 0,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
    },
  },
  subtitle: {
    marginBottom: '3vh'
  },
  subtitleMobile: {
    marginBottom: '3vh',
    maxWidth: '100%',
    marginRight: 16,
    textAlign: 'center',
  },
  imageLogo: {
    position: "absolute",
    top: 65,
    left: -160,
    opacity: 0,
  },
  imageAvatar: {
    position: "absolute",
    top: 30,
    right: -110,
    opacity: 0,
  },
  imageFav: {
    position: "absolute",
    top: 0,
    left: -165,
    opacity: 0,
  },
  imageStack: {
    position: "absolute",
    top: 200,
    left: -65,
    opacity: 0,
  },
  imageStackFirst: {
    top: 180,
  },
  mainBarImage: {
    position: "absolute",
    top: 296,
    left: 8,
    opacity: 0,
  },
  slateImage: {
    position: "absolute",
    left: 8,
    opacity: 0,
  },
  FABImage: {
    position: "absolute",
    left: 210,
    top: 590,
    opacity: 0,
    width: '35%',
  },
}));

const CARD_ITEMS = [
  {
    icon: Icon1,
    title: "Is your data hard to locate?",
    description:
      "Slate Pages solves the problem of disorganization, different formats, and data not being accessible when you need it. By using our cloud-based platform, your asset information is instantly available and organized.",
  },
  {
    icon: Icon2,
    title: "Are you still tracking with forms, files, and spreadsheets?",
    description:
      "Outdated techniques are slow, unstructured, and inefficient. Slate pages has an easy to use interface, customizable templates for data capture, and a powerful search engine, giving you the tools you need to stay on top of asset management.",
  },
  {
    icon: Icon3,
    title: "Do you want to extend the lifespan of your equipment?",
    description:
      "Effective maintenance and inspections will extend the longevity of your assets. By managing and tracking in Slate Pages, you will know what was inspected, by whom, and when.",
  },
];

const initialShowCards = [false, false, false]

const HomeBenefitsSection = ({imagesFormat}) => {
  const sectionRef = useRef(null);
  const phoneRef = useRef(null);
  const imagesRefs = useRef([]);
  const itemsRefs = useRef([]);
  const classes = useStyles();
  const imageMain = IMAGES.home.benefits.animation[imagesFormat];

  const imagesForAnimation = [
    {
      src: imageMain.logo.src,
      srcSet: imageMain.logo.srcSet,
      alt:'Logo',
      className: classes.imageLogo
    },
    {
      src: imageMain.avatar.src,
      srcSet: imageMain.avatar.srcSet,
      alt:'Avatar',
      className: classes.imageAvatar
    },
    {
      src: imageMain.fav1.src,
      srcSet: imageMain.fav1.srcSet,
      alt:'Fav 1',
      className: classes.imageFav
    },
    {
      src: imageMain.fav2.src,
      srcSet: imageMain.fav2.srcSet,
      alt:'Fav 2',
      className: classes.imageFav
    },
    {
      src: imageMain.stack1.src,
      srcSet: imageMain.stack1.srcSet,
      alt:'Stack 1',
      className: cx(classes.imageStack, classes.imageStackFirst)
    },
    {
      src: imageMain.stack2.src,
      srcSet: imageMain.stack2.srcSet,
      alt:'Stack 2',
      className: classes.imageStack
    },
    {
      src: imageMain.stack3.src,
      srcSet: imageMain.stack3.srcSet,
      alt:'Stack 3',
      className: classes.imageStack
    },
    {
      src: imageMain.allStacks.src,
      srcSet: imageMain.allStacks.srcSet,
      alt:'All Stacks',
      className: classes.imageStack
    },
    {
      src: imageMain.mainBar.src,
      srcSet: imageMain.mainBar.srcSet,
      alt:'Main Bar',
      className: classes.mainBarImage
    },
    {
      src: imageMain.slate1.src,
      srcSet: imageMain.slate1.srcSet,
      alt:'Slate 1',
      className: classes.slateImage
    },
    {
      src: imageMain.slate2.src,
      srcSet: imageMain.slate2.srcSet,
      alt:'Slate 2',
      className: classes.slateImage
    },
    {
      src: imageMain.slate3.src,
      srcSet: imageMain.slate3.srcSet,
      alt:'Slate 3',
      className: classes.slateImage
    },
    {
      src: imageMain.slate4.src,
      srcSet: imageMain.slate4.srcSet,
      alt:'Slate 4',
      className: classes.slateImage
    },
    {
      src: imageMain.FAB.src,
      srcSet: imageMain.FAB.srcSet,
      alt:'FAB',
      className: classes.FABImage
    },
  ]
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(initialShowCards);

  const openCard = (index) => {
    const newOpen = [...initialShowCards]
    newOpen[index] = true
    isSmScreen && setOpen(newOpen)
  }

  const closeCard = () => {
    setOpen(initialShowCards)
  }

  const [sectionPositionY, setSectionPositionY] = useState(0);

  const setSectionRef = useCallback((node) => {

    setSectionPositionY(changeClickScrollHeight(document.getElementById('container')));

    sectionRef.current = node;
    if (node) {
      const expand = {
        autoAlpha: 1,
        maxHeight: 320,
        boxShadow:
          '0px 0px 42px -10px rgba(122,122,122,0.65)'
      };
      const collapse = {
        autoAlpha: 0.5,
        maxHeight: 80,
        boxShadow:
          "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)",
        delay: 2,
      };

      gsap
        .timeline({
          defaults: { duration: 2 },
          scrollTrigger: {
            trigger: node,
            pin: true,
            scrub: 0.3,
            start: "345px 50%",
            end: `+=${BENEFITS_DURATION}`,
          },
        })
        // --- item 1 ---
        .add("start")
        .to(itemsRefs.current[0], expand)
        .to(itemsRefs.current[0], collapse)
        // --- item 2 ---
        .to(itemsRefs.current[1], expand)
        .to(itemsRefs.current[1], collapse)
        // --- item 3 ---
        .to(itemsRefs.current[2], expand)
        .to(itemsRefs.current[2], collapse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setItemsRef = useCallback((node, index) => {
    itemsRefs.current[index] = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setImagesRefs = useCallback((node, index) => {
    imagesRefs.current[index] = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPhoneRef = useCallback(node => {
    phoneRef.current = node;
    gsap
      .timeline({
        defaults: { duration: 2 },
        scrollTrigger: {
          trigger: node,
          scrub: 0.3,
          start: "20% bottom",
          end: "bottom 70%",
        },
      })
      // --- item 1 ---
      .add("start")
      // --- Logo ---
      .to(imagesRefs.current[0], { autoAlpha: 0, scale: 1, left: -160}, "start")
      .to(imagesRefs.current[0], { autoAlpha: 1, duration: 5, scale: .4, left: -45 }, "start")
      // --- Avatar ---
      .to(imagesRefs.current[1], { autoAlpha: 0, scale: .6, right: -110 }, "start")
      .to(imagesRefs.current[1], { autoAlpha: 1, duration: 5, scale: .25, right: 10 }, "start")

      // --- Favorite 1 ---
      .to(imagesRefs.current[2], { autoAlpha: 0, scale: .6, left: -265 }, "start+=2")
      .to(imagesRefs.current[2], { autoAlpha: 1, duration: 5, scale: .3, left: -65 }, "start+=3")
      // --- Favorite 2 ---
      .to(imagesRefs.current[3], { autoAlpha: 0, scale: .6, left: 65 }, "start+=3")
      .to(imagesRefs.current[3], { autoAlpha: 1, duration: 5, scale: .3, left: 65 }, "start+=4")

      // --- Stack 1 ---
      .to(imagesRefs.current[4], { autoAlpha: 0, scale: .6, left: -111, top: 230, }, "start+=5")
      .to(imagesRefs.current[4], { autoAlpha: 1, duration: 5, scale: .22, left: -11, top: 180, }, "start+=6")
      // --- Stack 2 ---
      .to(imagesRefs.current[5], { autoAlpha: 0, scale: .6, left: 5, top: 250, }, "start+=6")
      .to(imagesRefs.current[5], { autoAlpha: 1, duration: 5, scale: .22, left: 67, top: 200, }, "start+=7")
      // --- Stack 3 ---
      .to(imagesRefs.current[6], { autoAlpha: 0, scale: .6, left: 125, top: 250, }, "start+=7")
      .to(imagesRefs.current[6], { autoAlpha: 1, duration: 5, scale: .22, left: 125, top: 200, }, "start+=8")
      // --- Stack 4 ---
      .to(imagesRefs.current[7], { autoAlpha: 0, scale: .6, left: 335, top: 250, }, "start+=8")
      .to(imagesRefs.current[7], { autoAlpha: 1, duration: 5, scale: .22, left: 235, top: 200, }, "start+=9")

      // --- Main Bar ---
      .to(imagesRefs.current[8], { autoAlpha: 0, scale: .755 }, "start+=11")
      .to(imagesRefs.current[8], { autoAlpha: 1, duration: 5, scale: .755, }, "start+=14")

      // --- Slate 1 ---
      .to(imagesRefs.current[9], { autoAlpha: 0, scale: 1.75, top: 450, }, "start+=13")
      .to(imagesRefs.current[9], { autoAlpha: 1, duration: 5, scale: .65, top: 385, }, "start+=16")
      // --- Slate 2 ---
      .to(imagesRefs.current[10], { autoAlpha: 0, scale: 1.75, top: 500, }, "start+=15")
      .to(imagesRefs.current[10], { autoAlpha: 1, duration: 5, scale: .65, top: 445, }, "start+=18")
      // --- Slate 3 ---
      .to(imagesRefs.current[11], { autoAlpha: 0, scale: 1.75, top: 550, }, "start+=17")
      .to(imagesRefs.current[11], { autoAlpha: 1, duration: 5, scale: .65, top: 505, }, "start+=20")
      // --- Slate 4 ---
      .to(imagesRefs.current[12], { autoAlpha: 0, scale: 1.75, top: 600, }, "start+=19")
      .to(imagesRefs.current[12], { autoAlpha: 1, duration: 5, scale: .65, top: 565, }, "start+=22")
      // --- item 3 ---
      .to(imagesRefs.current[13], { autoAlpha: 0 }, "start+=25")
      .to(imagesRefs.current[13], { autoAlpha: 1, duration: 5 }, "start+=28")

  }, []);

  const onScroll = (index) => {
    const multiplier = BENEFITS_DURATION / CARD_ITEMS.length;
    const openOneItem = (BENEFITS_DURATION / CARD_ITEMS.length) / 2;
    const windowHeight = document.documentElement.clientHeight;
    // ------ sectionRef.current height (820px) + margin-top (100px)--------
    const containerHeight = 920

    const top = sectionPositionY - windowHeight / 2 + containerHeight / 2 + (index) * multiplier + openOneItem

    window && window.scrollTo(
      {
        top,
        behavior: "smooth",
      },
    )
  };


  useEffect(() => {
    const changeScrollHeight = () => {
      setSectionPositionY(changeClickScrollHeight(document.getElementById('container')))
    }
    window.addEventListener('resize', () => changeScrollHeight())
    return window.removeEventListener('resize', () => changeScrollHeight())
  }, []);

  const TitleRender = () => <>
      <Typography variant="h4" ml={2} className={isSmScreen ? classes.titleMobile : classes.title}>
        Slate Pages makes
      </Typography>
      <Typography variant="h4" mb={1} ml={2} className={isSmScreen ? classes.titleMobile : classes.title}>
        asset tracking easy.
      </Typography>
      <Subtitle align="left" mb={4} ml={2} className={isSmScreen ? classes.subtitleMobile : classes.subtitle}>
        Ditch the clipboard & delete the spreadsheet! The Slate Pages asset
        tracking app is the only solution you’ll need to record, organize,
        and store your asset’s information.
      </Subtitle>
    </>

  return (
    <>
      <div className={classes.mainContainer} id={'container'}>
        <Section
          container
          alignItems="flex-start"
          justify="space-between"
          ref={setSectionRef}
          className={classes.mainContainer}
        >
          <Grid item xs={12} sm={6} className={classes.imagesWrapper}>
            {imagesForAnimation.map(({src, srcSet, alt, className}, index) => (
              <ResponsiveImg
                key={alt}
                src={src}
                srcSet={`${srcSet} 2x`}
                alt={alt}
                className={className}
                ref={(node) => {
                  setImagesRefs(node, index);
                }}
              />
            ))}
            <ResponsiveImg
              src={IPhoneImg}
              alt="Slate Pages"
              className={classes.phoneImage}
              ref={setPhoneRef}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.contentWrapper}>
              <TitleRender/>
              {CARD_ITEMS.map(({ title,  ...rest }, index) => (
                <Grid key={title} mb={2.5}>
                  <BenefitCard
                    onClick={() => onScroll(index)}
                    title={title}
                    {...rest}
                    ref={(node) => {
                      setItemsRef(node, index);
                    }}
                  />
                </Grid>
              ))}
            </div>
          </Grid>
        </Section>
      </div>

      {
        isSmScreen && <div>
          <Section
            container
            alignItems="flex-start"
            justify="space-between"
            className={classes.mainContainerMobile}
          >
            <Grid item sm={12} md={6}>
              <div className={classes.contentWrapper}>
                <TitleRender/>
                <ClickAwayListener onClickAway={closeCard}>
                  <div>
                    {CARD_ITEMS.map(({ title, ...rest }, index) => (
                      <Grid key={title} mb={2.5}>
                        <BenefitCard
                            onClick={() => openCard(index)}
                            open={open[index]}
                            isMobile={true}
                            title={title}
                            {...rest}
                        />
                      </Grid>
                    ))}
                  </div>
                </ClickAwayListener>
              </div>
            </Grid>
          </Section>
        </div>
      }

      </>
  );
};

export default HomeBenefitsSection;