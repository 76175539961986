import React, { useRef, useCallback } from "react";
import { gsap } from "gsap";
import {
  Typography as MuiTypography,
  Grid as MuiGrid, Link as MuiLink,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Section from "../shared/section";
import Subtitle from "../shared/subtitle";
import IndustryCard from "./industry-card";
import CustomButton from "../shared/custom-button";
import { BENEFITS_DURATION } from "../../utils/durations";
import { LINKS } from "../../constants/links";
import { Link } from "gatsby";
import { IMAGES } from "../../constants/images";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
    },
  },
  cardsContainer:{
    height: 'auto'
  },
  innerClassName: {
    borderBottom: '1px solid #8080804d',
  },
}));


const HomeIndustriesSection = ({imagesFormat}) => {
  const sectionRef = useRef(null);
  const cardsRefs = useRef([]);
  const classes = useStyles();
  const imageMain = IMAGES.home.industries[imagesFormat];
  const CARD_ITEMS = [
    {
      image: imageMain.image_1.src,
      title: "Maintenance Tracking",
      description:
        "Increase the life expectancy of your equipment with effective maintenance tracking",
      href: LINKS.equipmentMaintenance,
    },
    {
      image: imageMain.image_2.src,
      title: "Asset Inventory Monitoring",
      description:
        "Access the condition, location, & lifespan of your assets in an instant",
      href: LINKS.inventoryManagement,
    },
    {
      image: imageMain.image_3.src,
      title: "Routine Inspections",
      description:
        "Effectively track equipment inspections & routine maintenance",
      href: LINKS.routineInspections,
    },
    {
      image: imageMain.image_4.src,
      title: "OEM Product Information",
      description:
        "Access & view asset details, manuals, warranty information & more",
      href: LINKS.manufacturingOem,
    },
  ];

  const setSectionRef = useCallback((node) => {
    sectionRef.current = node;
    if (node) {
      gsap.to(node, {
        scrollTrigger: {
          trigger: node,
          start: `50%+=${BENEFITS_DURATION} top`,
          end: `90%+=${BENEFITS_DURATION} top`,
          scrub: 0.5,
          // markers: true,
          id: "industry-container",
        },
        autoAlpha: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCardsRef = useCallback((node, index) => {
    cardsRefs.current[index] = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      ref={setSectionRef}
      pt={8}
      pb={15}
      sectionClassName={classes.section}
      innerClassName={classes.innerClassName}
    >
      <Typography variant="h4" align="center" className={classes.title}>
        Making a difference
      </Typography>
      <Typography variant="h4" align="center" mb={1} className={classes.title}>
        in your industry
      </Typography>
      <Subtitle mb={8}>
        The Slate Pages asset tracking system is flexible, improving your
        tracking procedures no matter the industry. Trusted by firefighters,
        mechanical, electrical and plumbing engineers, and more.
      </Subtitle>

      <Grid container spacing={4} mb={8}
            className={classes.cardsContainer}
            justify={'center'}
      >
        {CARD_ITEMS.map(({ title, ...rest }, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={title}
            ref={(node) => {
              setCardsRef(node, index);
            }}
          >
            <IndustryCard title={title} {...rest} />
          </Grid>
        ))}
      </Grid>

      <Grid container justify="center">
        <MuiLink
          component={Link}
          color="secondary"
          underline="none"
          to={LINKS.solutions}
        >
          <CustomButton variant="contained" color="primary">
            Discover your solution
          </CustomButton>
        </MuiLink>
      </Grid>
    </Section>
  );
};

export default HomeIndustriesSection;
